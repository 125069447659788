/* General footer styles */
footer {
  color: #fff; /* White text color */
  .lower_footer {
    background-color: #160f03;
  }
}

footer a {
  color: #ddd; /* Light gray color for links */
  text-decoration: none; /* Remove underline from links */
}

footer a:hover {
  color: #bbb; /* Slightly darker gray on hover */
}

footer h3 {
  color: #fff; /* White color for section headings */
}

footer p {
  color: #ddd; /* Light gray color for paragraphs */
}

/* Icon styling */
.anticon {
  font-size: 1.5rem; /* Size of icons */
  color: #fff; /* White color for icons */
}

/* Small phones (320px to 480px) */
@media (max-width: 480px) {
  .contactus{
    /* margin-top: -400px; */
  }
}
